<script setup lang="ts">
import {Link, usePage} from '@inertiajs/inertia-vue3';
import Banner from "@/Components/Banner.vue";
import {computed, ref} from "vue";
import RightTopNavigation from "@/Layouts/RightTopNavigation.vue";
import Footer from "@/Layouts/Footer.vue";
import CustomAlert from "@/Components/CustomAlert.vue";

const showingNavigationDropdown = ref(false);

const page = usePage();
const navigation = computed(() => usePage().props.value.menu);

const hasCompany = computed(() => page.props.value.company != null);
const bgColor = computed(() => hasCompany.value ? 'bg-white' : 'bg-buttonsPrimary');

document.documentElement.style.setProperty('--msv-color', page.props.value.primaryColor.toString());
document.documentElement.style.setProperty('--msv-color2', page.props.value.secondaryColor.toString());

</script>

<template>
	<div class="bg-white relative min-h-screen">
		<nav :class="bgColor" class=" border-b border-gray-100">
			<!-- Primary Navigation Menu -->
			<div class="mx-auto">
				<div class="flex justify-between h-16 p-3">
					<div class="flex">
						<!-- Logo -->
						<div class="flex-1 items-center">
							<Link :href="route('dashboard')">
								<img class="max-w-[16rem] object-contain h-full"
								     :src="page.props.value.company?.logo?.original_url ?? '/beeldbank_logo.png'"
								     alt="logo"/>
							</Link>
						</div>
					</div>

					<div class="hidden sm:flex sm:items-center sm:ml-6">
						<RightTopNavigation></RightTopNavigation>
					</div>

					<!-- Hamburger -->
					<div class="-mr-2 flex items-center sm:hidden">
						<button @click="showingNavigationDropdown = ! showingNavigationDropdown"
						        class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
							<svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
								<path
									:class="{'hidden': showingNavigationDropdown, 'inline-flex': ! showingNavigationDropdown }"
									stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
									d="M4 6h16M4 12h16M4 18h16"/>
								<path
									:class="{'hidden': ! showingNavigationDropdown, 'inline-flex': showingNavigationDropdown }"
									stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
									d="M6 18L18 6M6 6l12 12"/>
							</svg>
						</button>
					</div>
				</div>
			</div>
		</nav>

		<!-- Page Heading -->
		<header :class="bgColor" class="h-[400px]" v-if="$slots.header">
			<div class="mx-auto">
				<slot name="header"/>
			</div>
		</header>
		<Banner/>

		<!-- Page Content -->
		<main :class="bgColor" class="pb-24">
			<CustomAlert></CustomAlert>
			<slot/>
		</main>
		<Footer class="absolute bottom-0 left-0 right-0"></Footer>

	</div>
</template>
